<template>
  <div class="loufengSwiper">
    <van-swipe class="my-swipe" :autoplay="3000" @change="onChange">
      <van-swipe-item v-for="(item, index) in loufengSwipe.images" :key="index">
        <ImgDecypt class="tag-img" :src="item" fill="cover"></ImgDecypt>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{ loufengSwipe.images ? loufengSwipe.images.length : 0 }}</div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'loufengSwiper',
  props: {
    loufengSwipe: {}
  },
  data() {
    return {
      current: 0
    }
  },
  methods: {
    onChange(index) {
      this.current = index;
    }
  }
}
</script>

<style lang="scss" scoped>
  .loufengSwiper {
    .my-swipe{
      height: 460px;
      color: #fff;
      font-size: 20px;
      text-align: center;
      .custom-indicator {
        position: absolute;
        right: 16px;
        bottom: 10px;
        width: 44px;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 15px;
      }
      .tag-img{
        width:100%;
        height:100%;
      }
    }
  }
</style>
