<template>
  <div class="louFengDetail">
    <!-- 返回按钮 -->
    <div class="topBtn back" @click="$router.go(-1)">
      <van-icon name="arrow-left" size="25" color="white" />
    </div>
    <!-- 举报 -->
    <div class="topBtn warning" style="background-color: rgba(0,0,0,0.2)" @click="report">
      <!-- <van-icon name="warning" size="25" color="white" /> -->
    </div>
    <!-- 防骗指南 -->
    <div class="guide" @click="toGuide()"></div>
    <!-- 整页加载 -->
    <van-overlay :show="loading" z-index="101">
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#f21313">数据加载中...</van-loading>
      </div>
    </van-overlay>
    <!-- 楼凤图片 -->
    <LouFengSwiper :loufengSwipe="loufengSwipe" @click.native="toLoufengBigPicture"></LouFengSwiper>
    <!-- 跑马灯 -->
    <van-notice-bar background="linear-gradient(to right, rgb(244,43,27), rgb(248,173,62))" color="white" class="noticeBar"
      :text="loufengInfo.marquee"
    />
    <!-- 标题详情 -->
    <div class="titleBox">
      <h5>{{ loufengInfo.title }}</h5>
      <div class="assure" v-if="loufengInfo.agent ? (loufengInfo.agent.payable ? loufengInfo.agent.payable : false) : false">
        <div class="textImg"></div>
        <div class="text">
          <p>
            已缴纳保证金{{ loufengInfo.agent ? (loufengInfo.agent.deposit ? loufengInfo.agent.deposit : 0) : 0 }}元
          </p>
        </div>
      </div>
      <div class="actInfo">
        <div class="avatar" @click="toBrokerInfo(loufengInfo.agent)">
          <ImgDecypt v-if="loufengInfo.agent ? (loufengInfo.agent.avatar ? loufengInfo.agent.avatar : '') : ''" :src="loufengInfo.agent.avatar" />
        </div>
        <p class="name" @click="toBrokerInfo(loufengInfo.agent)">
          {{ loufengInfo.agent ? loufengInfo.agent.name : '-' }}
        </p>
        <p>
          注册于{{ formatDate(loufengInfo.agent ? loufengInfo.agent.createdAt: '') }}
        </p>
      </div>
      <div class="userData">
        <div class="browse">
          <div class="icon eye"></div>
          <p>{{ loufengInfo.looks }}</p>
        </div>
        <div class="browse">
          <div class="icon star"></div>
          <p>{{ loufengInfo.likes }}</p>
        </div>
        <div class="browse">
          <div class="icon lock"></div>
          <p>{{ loufengInfo.buys }}</p>
        </div>
        <div class="browse">
          <div class="icon exper"></div>
          <p>体验</p>
        </div>
      </div>
    </div>
    <div class="newUser">
      <div class="lookText">
        <p>新客必看</p>
      </div>
      <div class="isolation">|</div>
      <div class="suggest">
        <p>预约金可抵扣服务费，见面付尾款，必须使用平台支付预约金，若私下交易被骗，与平台无关</p>
      </div>
    </div>
    <!-- 联系方式 -->
    <div class="contact">
      <div class="top">
        <div class="icon"></div>
        <div class="title">联系方式</div>
      </div>
      <div class="content">
        <div class="show" v-if="loufengInfo.isBuy">
          <div class="show-title">{{ loufengInfo.contact }}</div>
          <p>提示1: 若对方要求定金，提高警觉！付款前一定查看「防骗指南」</p>
          <p>提示2: 搜索QQ后只点击查找人添加，底下群千万别加，都是骗子</p>
          <p>提示3: 请把所有联系方式都尝试一遍，全无效再举报</p>
        </div>
        <div class="hidden" v-else>
          <span style="color:red">***********</span>
          <div v-if="loufengInfo.type === 1">已隐藏，{{ loufengInfo.price }}金币解锁可免费看</div>
          <div v-if="loufengInfo.type === 2">已隐藏，{{ loufengInfo.bookPrice }}金币预约</div>
        </div>
      </div>
    </div>
    <!-- 基本信息 -->
    <div class="baseInfo">
      <div class="top">
        <div class="icon"></div>
        <div class="title">基本信息</div>
      </div>
      <div class="infoBox">
        <div class="info">
          <div class="icon quantity"></div>
          <div class="infoKey">妹妹数量：</div>
          <div class="infoValue">{{ loufengInfo.baseInfo ? (loufengInfo.baseInfo.quantity ? loufengInfo.baseInfo.quantity : 0) : 0 }}</div>
        </div>
        <div class="info">
          <div class="icon infos"></div>
          <div class="infoKey">妹子信息：</div>
          <div class="infoValue">{{ loufengInfo.baseInfo ? loufengInfo.baseInfo.age : 0 }}岁 {{ loufengInfo.baseInfo ? loufengInfo.baseInfo.height : 0 }}cm {{ loufengInfo.baseInfo ? loufengInfo.baseInfo.cup : 0 }}罩杯</div>
        </div>
        <div class="info">
          <div class="icon amount"></div>
          <div class="infoKey">服务价格：</div>
          <div class="infoValue" style="color:red">{{ loufengInfo.baseInfo ? (loufengInfo.baseInfo.srvPrice ? loufengInfo.baseInfo.srvPrice : '-') : '-' }}</div>
        </div>
        <div class="info">
          <div class="icon location"></div>
          <div class="infoKey">所在地区：</div>
          <div class="infoValue">{{ provinceCity }}</div>
        </div>
        <div class="info">
          <div class="icon time"></div>
          <div class="infoKey">服务时间：</div>
          <div class="infoValue">{{ loufengInfo.baseInfo ? (loufengInfo.baseInfo.timeOpen ? loufengInfo.baseInfo.timeOpen : '-') : '-' }}</div>
        </div>
        <div class="info isolate">
          <div class="icon server"></div>
          <div class="infoKey">服务项目：</div>
          <div class="infoValue">{{ loufengInfo.baseInfo ? (loufengInfo.baseInfo.service ? loufengInfo.baseInfo.service : '-') : '-' }}</div>
        </div>
        <div class="introduce" v-if="loufengInfo.baseInfo ? loufengInfo.baseInfo.details : false">
          {{ loufengInfo.baseInfo ? (loufengInfo.baseInfo.details ? loufengInfo.baseInfo.details : '-') : '-' }}
        </div>
      </div>
    </div>
    <!-- 综合评分 -->
    <div class="overallRating">
      <div class="top">
        <div class="icon"></div>
        <div class="title">综合评分</div>
      </div>
      <div class="complex">
        <div class="score">
          <span style="font-size:40px;color:rgb(242,163,47)">{{ loufengInfo.scoreInfo ? (loufengInfo.scoreInfo.score ? loufengInfo.scoreInfo.score : '-') : '-' }}</span>
          <span>综合评分(满分100分)</span>
        </div>
        <div class="star">
          <span>妹妹颜值：</span>
          <van-rate
            :value="loufengInfo.scoreInfo ? loufengInfo.scoreInfo.faceValue: 0"
            :size="16"
            color="rgb(242,163,47)"
            void-icon="star"
            void-color="#eee"
            readonly
          />
          <br/>
          <span>服务星级：</span>
          <van-rate
            :value="loufengInfo.scoreInfo ? loufengInfo.scoreInfo.serviceStar: 0"
            :size="16"
            color="rgb(242,163,47)"
            void-icon="star"
            void-color="#eee"
            readonly
          />
          <br/>
          <span>环境设备：</span>
          <van-rate
            :value="loufengInfo.scoreInfo ? loufengInfo.scoreInfo.environ: 0"
            :size="16"
            color="rgb(242,163,47)"
            void-icon="star"
            void-color="#eee"
            readonly
          />
          <br/>
        </div>
      </div>
    </div>
    <!-- 体验详情 -->
    <div class="experDetail">
      <div class="top">
        <div class="icon"></div>
        <div class="title">体验详情</div>
      </div>
      <div class="detail">
        <van-list
          v-model="onLoading"
          :finished="finished"
          finished-text="呀～已经到底了"
          @load="onLoad"
        >
          <div class="detail-item" v-for="(item, index) in experDetails" :key="index">
            <div class="experAvatar">
              <div class="img">
                <ImgDecypt :round="true" v-if="item.userInfo ? (item.userInfo.avatarUrl ? item.userInfo.avatarUrl : '') : ''" :src="item.userInfo.avatarUrl" />
              </div>
              <span style="margin-left: 5px;">{{ item.userInfo ? (item.userInfo.nickName ? item.userInfo.nickName : '') : '' }}</span>
            </div>
            <p>{{ item.content }}</p>
          </div>
        </van-list>
      </div>
    </div>
    <div class="footer">
      <div class="footerBtn" @click="like">
        <div v-if="isLike" class="like">
          <van-icon name="like" size="16" color="rgb(242,163,47)" />
          <span style="color:rgb(242,163,47)">已收藏</span>
        </div>
        <div v-else class="like">
          <van-icon name="like-o" size="16" />
          <span style="margin-left:3px">收藏</span>
        </div>
      </div>
      <div class="footerBtn" @click="publish">
        <div class="exper"></div>
        <span>发布体验</span>
      </div>
      <div class="btn" @click="showPopup(loufengInfo.type)" v-if="!loufengInfo.isBuy">
        <p class="lock"></p>
        <span v-if="loufengInfo.type === 1">{{ loufengInfo.price }}金币解锁</span>
        <span v-if="loufengInfo.type === 2">{{ loufengInfo.bookPrice }}金币预约</span>
      </div>
      <div class="btn" v-else style="background-image:linear-gradient(to right, rgb(160,160,160), rgb(160,160,160))">
        <p class="unlock"></p>
        <span v-if="loufengInfo.type === 1">已解锁</span>
        <span v-if="loufengInfo.type === 2">已付{{ loufengInfo.bookPrice }}金币预约</span>
      </div>
      <van-popup v-model="loufengShow" round position="bottom" class="pay-box" @close="close">
        <div class="buyLoading" v-if="buyLoading">
          <van-loading type="spinner" color="#f21313">&nbsp;&nbsp;加载中...</van-loading>
        </div>
        <div class="pay-title">请选择楼凤折扣优惠券</div>
        <div class="pay-money">
          <div class="pay-money-icon"></div>
          <span>{{ loufengInfo.price === 0 ? 0 : discountedPrice }}</span>
        </div>
        <div class="initPrice" v-if="initPrice !== 90">
          原价：<span style="text-decoration:line-through;">90</span>
        </div>
        <div class="pay-layout">
          <p style="color:red">升级会员享全场免费解锁</p>
          <div class="pay-layout-icon" @click="toUpVip">升级会员</div>
        </div>
        <div class="pay-layout">
          <p>资源信息</p>
          <p>{{ loufengInfo.title }}</p>
        </div>
        <div class="pay-layout">
          <p>发布用户</p>
          <p>{{ loufengInfo.agent ? loufengInfo.agent.name : '-' }}</p>
        </div>
        <div class="pay-coupon-box">
          <van-radio-group v-model="couponId">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in coupons" :key="index" :title="item.name"
              >
                <template #right-icon>
                  <p style="margin-right: 50px;font-size:13px">剩余：{{ item.count }}</p>
                  <van-radio v-if="item.count > 0" :name="item.id" shape="square" @click="changePrice(item.discountedPrice, item.id, item.count)"/>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <div class="pay-btn" v-if="userInfo.balance < loufengInfo.price" @click="toRecharge">余额不足，立即充值</div>
        <div class="pay-btn" v-else @click="buy">确认购买</div>
      </van-popup>
      <van-popup v-model="visitShow" round position="bottom" class="pay-box" >
        <div class="buyLoading" v-if="buyLoading">
          <van-loading type="spinner" color="#f21313">&nbsp;&nbsp;加载中...</van-loading>
        </div>
        <div class="pay-title">认证专区预约</div>
        <div class="pay-money">
          <div class="pay-money-icon"></div>
          <span>{{ loufengInfo.bookPrice }}</span>
        </div>
        <div class="initPrice" v-if="!loufengInfo.upgradeTips">
          原价：<span style="text-decoration:line-through;">{{ loufengInfo.originalBookPrice }}</span>
        </div>
        <div class="pay-layout" v-if="!loufengInfo.upgradeTips">
          <p>我的权益</p>
          <p style="color:red">{{ loufengInfo.rightDesc }}</p>
        </div>
        <div class="pay-layout" v-else>
          <p style="color:red">会员可享最低7折预约折扣</p>
          <div class="pay-layout-icon" @click="toUpVip">升级会员</div>
        </div>
        <div class="pay-layout">
          <p>资源信息</p>
          <p>{{ loufengInfo.title }}</p>
        </div>
        <div class="pay-layout">
          <p>发布用户</p>
          <p>{{ loufengInfo.agent ? loufengInfo.agent.name : '-' }}</p>
        </div>
        <div class="pay-btn-book" v-if="userInfo.balance < loufengInfo.bookPrice" @click="toRecharge">余额不足，立即充值</div>
        <div class="pay-btn-book" v-else @click="book">立即预约</div>
      </van-popup>
    </div>
    <van-overlay :show="isShow" @click="isShow = false" :z-index="20">
      <div class="wrapper">
        <div class="block">
          <div style="font-size: 20px;font-weight: 800;">
            温馨提示
          </div>
          <div style="font-size:14px">
            {{ showText }}
          </div>
          <div class="knowBtn">
            知道了
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getLoufengInfo, getLoufengExperDetails, updateLike, getCoupons, buyLouFeng, bookLouFeng } from '@/api/loufeng.js';
import { getUserInfo } from '@/api/user.js';
import LouFengSwiper from '@/components/LouFengSwiper/index';

export default {
  name: 'louFengDetail',
  components: {
    LouFengSwiper
  },
  data() {
    return {
      loufengId: '',
      starVal: 3,
      loufengInfo: {},
      loufengShow: false,
      visitShow: false,
      loufengSwipe: {
        videos: undefined,
        images: undefined
      },
      loading: true, // 整个页面数据加载
      experDetails: [], // 体验详情
      pageNum: 1,
      pageSize: 10,
      onLoading: false, // 详情上拉加载页
      finished: false, // 内容是否结束
      isLike: undefined, // 是否已收藏
      isShow: false, // 温馨提示 
      showText: '', // 提示文本
      userInfo: {
        balance: 0
      }, // 用户信息，余额默认为0
      coupons: [], // 折扣券
      discountedPrice: 0, // 购买时显示的价格
      initPrice: 0, // 初始价格
      oldCouponId: '', // 旧优惠券ID
      couponId: '', // 优惠券ID
      buyLoading: false,
      loufengType: '', // 区分楼凤的类型， ordinary - 普通楼凤(90金币)； authenticate - 认证； pay - 赔付；
    }
  },
  created() {
    this.getUserInfo();
    this.getLoufengId();
    this.getLoufengInfo();
    this.getLoufengExperDetails();
  },
  computed: {
    provinceCity() {
      let province = this.loufengInfo.baseInfo ? (this.loufengInfo.baseInfo.province ? this.loufengInfo.baseInfo.province : '') : '';
      let city = this.loufengInfo.baseInfo ? (this.loufengInfo.baseInfo.city ? this.loufengInfo.baseInfo.city : '') : '';
      if(province && city) {
        return province + '-' + city;
      } else if(province && !city) {
        return province;
      } else if(!province && city) {
        return city;
      } else {
        return '-';
      }
    }
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      let ret = await this.$Api(getUserInfo, undefined);
      if (ret && ret.code == 200) {
        this.userInfo = ret.data.info;
      }
    },
    // 获取当前页面的楼凤类型
    getLoufengType() {
      if(this.loufengInfo.type === 1) {
        this.loufengType = 'ordinary';
      } else if(this.loufengInfo.type === 2) {
        this.loufengType = 'authenticate';
      }
      // console.log(this.loufengType);
    },
    // 获取折扣券数量
    async getCoupons() {
      this.buyLoading = true;
      let req = {
        productId: parseInt(this.loufengId),
        productType: 'louFeng'
      }
      let res = await this.$Api(getCoupons, req);
      if(res && res.code === 200) {
        this.initPrice = res.data.discountedPrice;
        this.discountedPrice = res.data.discountedPrice;
        this.coupons = res.data.couponList;
        this.buyLoading = false;
      } else {
        this.$toast('优惠券获取异常');
      }
    },
    // 选择优惠券改变价格
    changePrice(price, id, count) {
      if(count > 0) {
        if(id === this.oldCouponId) {
          this.couponId = null;
          this.oldCouponId = null;
          this.discountedPrice = this.initPrice;
        } else {
          this.oldCouponId = id;
          this.discountedPrice = price; // 勾选优惠券时的价格;
        }
      } else {
        this.$toast('目前没有该优惠券');
      } 
    },
    // 关闭弹出层
    close() {
      this.couponId = null;
      this.oldCouponId = null;
      this.discountedPrice = this.initPrice;
    },
    /**
     * 判断路由参数中是否存在loufengId
     * 如果存在，说明是从别的页面跳转过来，直接使用里面的值即可，并且要保存此楼凤ID，防止当前页面刷新，ID丢失；
     * 如果不存在，则需要从localstorage里面获取；
     * */
    getLoufengId() {
      let tempLoufengId = this.$route.params.id;
      if(!tempLoufengId) {
        this.loufengId = localStorage.getItem('loufengId');
      } else {
        this.loufengId = tempLoufengId;
        localStorage.setItem('loufengId', this.$route.params.id);
      }
    },
    // 获取楼凤具体信息
    async getLoufengInfo() {
      let req = {
        lfId: parseInt(this.loufengId)
      }
      let res = await this.$Api(getLoufengInfo, req);
      if(res && res.code === 200) {
        this.loufengInfo = res.data.lfInfo;
        this.loufengSwipe.images = this.loufengInfo.images;
        this.isLike = this.loufengInfo.isLike;
        // this.getLoufengType(); // 获取楼凤类型
        this.loading = false;
      } else {
        this.$toast('楼凤详情数据请求异常');
      }
    },
    // 获取楼凤体验详情
    async getLoufengExperDetails() {
      let req = {
        girlId: parseInt(this.loufengId),
        girlType: 'loufeng',
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      let res = await this.$Api(getLoufengExperDetails, req);
      this.onLoading = false;
      if(res && res.code === 200) {
        let dataSize = res.data.list.length;
        if(res.data && dataSize > 0) {
          this.experDetails = this.experDetails.concat(res.data.list);
        }
        if(res.data && dataSize < this.pageSize) {
          this.finished = true;
        }
      } else {
        this.$toast('体验报告数据请求异常');
      }
    },
    // 加载体验详情数据
    onLoad() {
      this.pageNum++;
      this.onLoading = false;
      this.getLoufengExperDetails();
    },
    // 去楼凤放大图
    toLoufengBigPicture() {
      this.$router.push({
        name: 'loufengBigPicture',
        params: {
          loufengSwipe: this.loufengSwipe
        }
      })
    },
    // 打开防骗指南
    toGuide() {
      this.$router.push({
        name: 'guide'
      })
    },
    // 是否收藏楼凤
    async like() {
      let req = {
        lfId: parseInt(this.loufengId),
        like: !this.isLike
      }
      let res = await this.$Api(updateLike, req);
      if(res && res.code === 200) {
        this.isLike = !this.isLike;
        if(this.isLike) {
          this.$toast('已收藏');
        } else {
          this.$toast('已取消收藏');
        }
      } else {
        this.$toast('收藏异常');
      }
    },
    // 去经纪人页面
    toBrokerInfo(param) {
      let temp = this.$route.params.isFromBroker;
      if(temp) {
        this.$router.go(-1);
        return;
      }
      this.$router.push({
        name: 'brokerInfo',
        params: {
          agent: param
        }
      })
    },
    // 举报
    report() {
      if(this.loufengInfo.isBuy) {
        this.$router.push({
          name: 'loufengReport'
        })
      } else {
        this.isShow = true;
        this.showText = '购买后才能举报';
      }
    },

    // 发布体验
    publish() {
      if(this.loufengInfo.isBuy) {
        this.$router.push({
          name: 'loufengExperReort'
        })
      } else {
        this.isShow = true;
        this.showText = '购买后才能发布体验';
      }
    },
    // 购买楼凤信息
    async buy() {
      let req = {
        couponId: this.couponId,
        lfId: parseInt(this.loufengId)
      }
      let res =  await this.$Api(buyLouFeng, req);
      if(res && res.code === 200) {
        this.$toast('购买成功');
        this.getUserInfo();
        this.loufengInfo.isBuy = true;
        this.loufengInfo.contact = res.data.contact;
        this.loufengShow = false;
      } else {
        this.$toast('购买异常')
      }
    },
    // 预约楼凤
    async book() {
      let req = {
        lfId: parseInt(this.loufengId)
      }
      let res =  await this.$Api(bookLouFeng, req);
      if(res && res.code === 200) {
        this.$toast('预约成功');
        this.getUserInfo();
        this.loufengInfo.isBuy = true;
        this.loufengInfo.contact = res.data.contact;
        this.visitShow = false;
      } else {
        this.$toast('预约异常')
      }
    },
    // 打开底部
    showPopup(type) {
      if(type === 1) {
        this.loufengShow = true;
      } else if(type === 2) {
        this.visitShow = true;
      }
      this.getCoupons();
    },
    // 去升级会员
    toUpVip() {
      this.$router.push({
        name: 'vipList'
      })
    },
    // 去充值
    toRecharge() {
      this.$router.push({
        name: 'wallet'
      })
    },
    // 工具
    // 格式化日期
    formatDate(time) {
      if(time) {
        let tempTime = new Date(time);
        return tempTime.getFullYear() + '/' + (tempTime.getMonth() + 1) + "/" + tempTime.getDate();
      } else {
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
  }
  .louFengDetail {
    background-color: rgb(245,245,245);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    // 顶部按钮
    .topBtn {
      height: 40px;
      width: 40px;
      background-color: rgba(0, 0,0, 0.2);
      border-radius: 50%;
      position: fixed;
      z-index: 15;
      top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // 返回
    .back {
      left: 10px;
    }
    // 温馨提示
    .warning {
      right: 10px;
      background: url('../../../assets/png/warning.png') center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
    // 防骗指南
    .guide {
      height: 90px;
      width: 80px;
      background-image: url('../../../assets/png/guide.png');
      background-repeat: no-repeat;
      background-size: contain;
      position: fixed;
      z-index: 15;
      right: 15px;
      bottom: 60px;
    }
    // 页面加载loading
    .loading {
      height: 100vh;
      width: 100%;
      line-height: 100vh;
      position: fixed;
      background-color: white;
      z-index: 20;
    }
    // 跑马灯样式
    .noticeBar {
      height: 30px;
      font-size: 13px;
      margin-top: -3px;
    }
    // 详情样式
    .titleBox {
      padding: 10px;
      h5{
        font-size: 14px;
      }
      .assure {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
        .textImg {
          height: 30px;
          width: 30px;
          background: url('../../../assets/png/save.png') center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .text {
          height: 21px;
          width: 122px;
          border-radius: 10.5px 10.5px;
          background-color: rgb(255,243,226);
          line-height: 21px;
          text-align: center;
          color: rgb(210,36,36);
          p {
            transform: scale(0.8, 0.8);
          }
        }
      }
      .actInfo {
        display: flex;
        height: 24px;
        align-items: center;
        margin-top: 5px;
        .avatar {
          height: 24px;
          width: 24px;
        }
        .name {
          color: rgb(66,145,223);
          font-size: 12px;
          margin: 0 10px;
        }
        p {
          font-size: 12px;
          color: rgb(112,112,112);
        }
      }
      // 用户数据
      .userData {
        display: flex;
        margin-top: 10px;
        .browse {
          display: flex;
          align-items: center;
          margin-right: 15px;
          .icon {
            width: 16px;
            height: 16px;
          }
          .eye {
            background: url('../../../assets/png/eye.png') center center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          .star {
            background: url('../../../assets/png/star.png') center center;
            background-size: 14px 14px;
            background-repeat: no-repeat;
          }
          .lock {
            background: url('../../../assets/png/lock.png') center center;
            background-size: 14px 14px;
            background-repeat: no-repeat;
          }
          .exper {
            background: url('../../../assets/png/exper.png') center center;
            background-size: 12px 14px;
            background-repeat: no-repeat;
          }
          p {
            transform: scale(0.9, 0.9);
            color: rgba(0,0,0,0.5);
            margin-left: 2px;
          }
        }
      }
    }
    // 新客必看
    .newUser {
      height: 56px;
      width: 100%;
      background-color: rgb(245, 219, 182);
      display: flex;
      align-items: center;
      .lookText {
        font-size: 20px; 
        color: red;
        flex: 1;
        text-align: center;
        p {
          height: 44px;
          width: 44px;
          display: inline-block;
        }
      }
      .suggest {
        flex: 4;
        font-size: 13px;
        line-height: 18px;
        margin-right: 2%;
        p {
          width: 97%;
          margin-left: 3%;
        }
      }
    }
    .top {
      height: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      .icon {
        width: 3px;
        height: 100%;
        border-radius: 3px 3px;
        background-color: red;
      }
      .title {
        font-size: 16px;
        margin-left: 3px;
      }
    }
    // 联系方式
    .contact {
      padding: 15px 10px 5px 10px;
      font-size: 13px;
      .content {
        min-height: 30px;
        background-color: white;
        padding: 0px 10px;
        margin-top: 8px;
        border-radius: 5px 5px;
        box-shadow: 0px 1px 1px rgba(112,112,112, 0.2);
        .hidden {
          height: 30px;
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .show {
          padding: 10px 0 10px 0;
          .show-title {
            min-height: 30px;
            line-height: 25px;
            font-size: 16px;
          }
          p {
            color: rgba(255,0,0,0.8);
            line-height: 20px;
          }
        }
      }
    }
    // 基本信息
    .baseInfo{ 
      padding: 12px 10px;
      font-size: 13px;
      .infoBox {
        padding: 15px 17px;
        background-color: white;
        margin-top: 10px;
        border-radius: 5px;
        box-shadow: 0px 1px 1px rgba(112,112,112, 0.2);
        .info {
          min-height: 30px;
          width: 100%;
          display: flex;
          .icon {
            height: 15px;
            width: 15px;
          }
          .quantity {
            background: url('../../../assets/png/quantity.png') center center;
            background-size: 13px 13px;
            background-repeat: no-repeat;
          }
          .infos {
            background: url('../../../assets/png/info.png') center center;
            background-size: 13px 13px;
            background-repeat: no-repeat;
          }
          .amount {
            background: url('../../../assets/png/amount.png') center center;
            background-size: 13px 13px;
            background-repeat: no-repeat;
          }
          .location {
            background: url('../../../assets/png/location.png') center center;
            background-size: 13px 13px;
            background-repeat: no-repeat;
          }
          .time {
            background: url('../../../assets/png/time.png') center center;
            background-size: 13px 13px;
            background-repeat: no-repeat;
          }
          .server {
            background: url('../../../assets/png/server.png') center center;
            background-size: 13px 13px;
            background-repeat: no-repeat;
          }
          .infoKey {
            margin-left: 5px;
          }
          .infoValue {
            color: rgb(112,112,112);
            width: 220px;
          }
        }
      }
      .introduce {
        padding: 15px 15px 12px 14px;
        background-color: rgb(244,244,244);
        line-height: 20px;
        border-radius: 5px;
      }
    }
    // 综合评分
    .overallRating {
      padding: 12px 10px;
      font-size: 13px;
      .complex {
        display: flex;
        justify-content: space-between;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 1px 1px rgba(112,112,112, 0.2);
        padding: 15px 25px 12px 10px;
        margin-top: 8px;
        font-size: 12px;
        .score {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .star {
          line-height: 20px;
        }
      }
    }
    // 体验详情
    .experDetail {
      padding: 12px 10px;
      font-size: 13px;
      .detail {
        background-color: white;
        padding: 12px 10px;
        box-shadow: 0px 1px 1px rgba(112,112,112, 0.2);
        margin-top: 8px;
        border-radius: 5px;
        font-size: 12px;
        margin-bottom: 40px;
        .detail-item {
          margin-bottom: 10px;
          .experAvatar {
            display: flex;
            align-items: center;
            .img {
              height: 34px;
              width: 34px;
            }
          }
          p {
            padding: 8px 5px 0px 0px;
            line-height: 15px;
          }
        }
      }
    }
    .footer {
      height: 50px;
      width: 100%;
      background-color: rgb(240, 239, 239);
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      z-index: 16;
      .footerBtn {
        display: flex;
        align-items: center;
        font-size: 14px;
        .like {
          display: flex;
          align-items: center;
        }
        .exper {
          height: 18px;
          width: 20px;
          background: url('../../../assets/png/exper.png') center center;
          background-repeat: no-repeat;
          background-size: 12px 16px;
        }
      }
      .btn {
        width: 170px;
        height: 40px;
        border-radius: 20px;
        background-image: linear-gradient(to right, rgb(234,42,42), rgb(239,169,58));
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          height:15px;
          width: 15px;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .lock {
          background-image: url('../../../assets/png/lock2.png');
        }
        .unlock {
          background-image: url('../../../assets/png/unlock.png');
        }
        span {
          margin-left:3px;
          font-size:14px;
          color:white;
        }
      }
    }
    .isolate {
      padding-bottom: 5px;
    }
    // 提示框
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .block {
      width: 280px;
      height: 150px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .knowBtn {
        width: 150px;
        height: 30px;
        background-color: line;
        background-image: linear-gradient(to right,rgb(234,42,42), rgb(239,169,58));
        border-radius: 17px;
        text-align: center;
        line-height: 30px;
        color: white;
      }
    }
    // 底部支付页
    .pay-box {
      height: 380px;
      .buyLoading {
        position: fixed;
        z-index: 1;
        height: 380px;
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .pay-title {
        font-size: 16px;
        font-weight: 800;
        text-align: center;
        height: 40px;
        line-height: 55px;
      }
      .initPrice {
        font-size: 14px;
        text-align: right;
        padding: 0 15px;
        border-bottom: rgba(112,112,112, 0.1) solid 1px;
        line-height: 30px;
      }
      .pay-money {
        font-size: 35px;
        color: red;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .pay-money-icon {
          height: 30px;
          width: 30px;
          margin-right: 10px;
          background-image: url('../../../assets/png/money_icon.png');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .pay-layout {
        color: rgb(146, 141, 141);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 0 12px;
        height: 35px;
        border-bottom: rgba(112,112,112, 0.1) solid 1px;
        .pay-layout-icon {
          height: 22px;
          width: 70px;
          font-size: 12px;
          color: black;
          background-image: linear-gradient(to right, rgb(242,169,90), rgb(255,242,165), rgb(242,171,90));
          line-height: 22px;
          text-align: center;
          border-radius: 15px;
        }
      }
      .pay-coupon-box {
        padding: 0 20px;
        height: 80px;
        overflow: auto;
        /deep/ .van-cell__title {
          color: red;
          font-size: 14px;
        }
        /deep/ .van-hairline--top-bottom::after {
          border-width: unset;
        }
        /deep/ [class*=van-hairline]::after {
          border: unset;
        }
        /deep/ .van-cell::after {
          border-bottom: unset;
        }
        .van-cell{
          line-height: 25px;
        }
      }
      .pay-btn {
        height: 40px;
        width: 240px;
        background-image: linear-gradient(to right, rgb(234,42,42), rgb(239,169,58));
        line-height: 40px;
        text-align: center;
        font-size: 15px;
        color: white;
        border-radius: 20px;
        position: relative;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .pay-btn-book {
        height: 40px;
        width: 240px;
        background-image: linear-gradient(to right, rgb(234,42,42), rgb(239,169,58));
        line-height: 40px;
        text-align: center;
        font-size: 15px;
        color: white;
        border-radius: 20px;
        position: relative;
        top: 60px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    // 整页加载
    .wrapper-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: white;
    }
    /deep/ .van-loading--spinner {
      margin-top: unset;
    }
  }
</style>
